header.sticky {
  padding: "20px";
  background-color: #3c4b64;
  transition: 0.4s;
  height: 70px;
}

header.sticky .button {
  background-color: #fff;
  color: #3c4b64;
}

header.sticky :hover.hover {
  color: #D29725;
  font-weight: bolder;
}

:hover.hover {
  color: #3c4b64;
  font-weight: bolder;
}
@media (max-width: 900px) {
  header.sticky {
    display: none;
    background-color: transparent;
  }
}
