@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #d4aa70 #e4e4e4;
  transition: scrollbar-color 0.3s ease-out;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #0f9347;
}

::-webkit-scrollbar {
  width: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

header.sticky {
  padding: "20px";
  background-color: #3c4b64;
  transition: 0.4s;
  height: 70px;
}

header.sticky .button {
  background-color: #fff;
  color: #3c4b64;
}

header.sticky :hover.hover {
  color: #D29725;
  font-weight: bolder;
}

:hover.hover {
  color: #3c4b64;
  font-weight: bolder;
}
@media (max-width: 900px) {
  header.sticky {
    display: none;
    background-color: transparent;
  }
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #38c072;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 36px;
  height: 36px;
  right: 36px;
  top: 36px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #38c072;
  padding: 2.4em 2.5em 0;
  font-size: 1.8em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #38c072;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item {
  text-decoration: none;
  color: white;
  font-weight: bold;
  line-height: 2;
}

@media (min-width: 1000px) {
  .bm-burger-button{
    display: none;
  }
  .bm-burger-bars {
    display: none !important;
  }
}

.sticky {
  padding: "20px";
  background-color: #3c4b64;
  transition: 0.4s;
  height: 70px;
}

